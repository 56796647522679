import module from 'module';
import './pawn-auction-list.style.less';

const templateUrl = require('./pawn-auction-list.template.html');
module.component('pawnAuctionList', {
  templateUrl,
  controller: function ($route, http, authentication, pawnAuctionCache, confirmation, notification, pawnProductsCache) {

    let self = this;

    const form = index => self[`form_${index}`];

    self.canManageAuctions = authentication.permissions['MNG_PWN_AUCTIONS_WRITE'];
    self.branchId = $route.current.params.branchId;
    self.auctions = {};

    const auctionsSub = pawnAuctionCache.branchAuctions(self.branchId)
      .toObservable().subscribe(auctions => self.auctions = auctions);

    const pawnTypesSub = pawnProductsCache.toObservable()
      .subscribe(pawnTypes => {
        self.pawnTypes = pawnTypes;
        self.pawnTypes.forEach(pt => pt.name = pt.productDefinition.productName);
      });

    self.isSaveEnabled = index => form(index).$valid;

    self.formatPawnTypes = (item) => {
      if (!item.pawnTypeIds) {
        return 'All';
      } else {
        return item.pawnTypeIds
          .map(ptId => self.pawnTypes.find(pt => Number(pt.id) === Number(ptId)))
          .map(pt => pt.name)
          .join(', ');
      }
    };

    self.addAuction = () => {
      self.auctions.push({dirty: true, allPawnTypes: true});
    };

    self.cancelAuction = () => {
      self.auctions.pop();
    };

    self.saveAuction = index => {
      form(index).$setSubmitted();
      if (form(index).$valid) {
        confirmation('Do you want to save auction?', () => {
          http.post(
            '/products/pawns/auctions',
            {
              branchId: parseInt(self.branchId),
              name: self.auctions[index].name,
              pawnTypeIds: self.auctions[index].allPawnTypes ? null : self.auctions[index].pawnTypeIds,
              date: self.auctions[index].date,
              pawnReleaseFrom: self.auctions[index].pawnReleaseFrom,
              pawnReleaseTo: self.auctions[index].pawnReleaseTo,
            },
            {nxLoaderText: 'Saving auction'}
          )
            .success(response => {
              self.auctions[index].dirty = false;
              self.auctions[index].id = response.id;
              pawnAuctionCache.branchAuctions(self.branchId).refetch();
              notification.show("Auction has been saved.");
            })
            .error(() => {
              notification.show("Error", "Could not save auction.");
            });
        });
      }
    };

    self.deleteAuction = index => {
      confirmation('Do you want to remove auction?', () => {
        http.doDelete(`/products/pawns/auctions/${self.auctions[index].id}`, {nxLoaderText: 'Removing auction'})
          .success(() => {
            pawnAuctionCache.branchAuctions(self.branchId).refetch();
            notification.show("Auction has been removed.");
            self.auctions.splice(index, 1);
          })
          .error(() => {
            notification.show("Error", "Could not remove Auction.");
          });
      });
    };

    self.$onDestroy = () => {
      auctionsSub.unsubscribe();
      pawnTypesSub.unsubscribe();
    }
  }
})
;
