import {Observable} from 'rxjs/Observable'
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/combineLatest';
import _ from 'lodash';
import moment from 'moment';

import module from 'module';
import {Statuses, typeColor} from './action-list.component';

const templateUrl = require('./action-details.template.html');


module.component('dashboardActionDetails', {
  templateUrl,
  bindings: {
    action: '<',
    onHide: '&',
    onSuccess: '&'
  },
  controller: function ($filter, $q, $location, customerCache, productDefinitionService, http, dict, dashboardActionService) {
    const that = this;

    that.keyValueDetails = [];
    that.overrideTask = null;

    const formatUser = user => user ? `${user.lastName}, ${user.firstName} ${user.middleName || ''} (${user.username})` : '-';

    const fetchTaskDetails = (actionId, options) =>
      http.get(`/tasks/${actionId}/object`, options);

    that.localOverride = (action, $event) => {
      if ($event) $event.stopPropagation();
      that.overrideTask = action;
    };

    that.pinEntered = (task, pin, user) => dashboardActionService.pinEntered(task, pin, user, (task) => {
      that.onSuccess();
    });

    that.approve = (task, $event) => dashboardActionService.approve(task, $event, (task) => {
      that.onSuccess();
    });

    that.reject = (task, $event) => dashboardActionService.reject(task, $event, (task) => {
      that.onSuccess();
    });

    const translateName = (action) => {
      let name = action.name;
      if (action.taskGroup === 'COMMAND_APPROVAL') {
        name = $filter('translateEnum')(name, 'COMMAND');
      }
      return name;
    }

    that.$onInit = () => {
      const action = that.action;
      const keyValueDetails = [
        {
          label: 'Action no',
          value: action.id,
        }, {
          label: 'Action status',
          value: Statuses[action.approvalStatus],
        }, {
          label: 'Action type',
          value: translateName(action),
          clazz: typeColor(action),
        }, {
          label: 'Initiated by',
          value: formatUser(action.registrationUser),
        }, {
          label: 'Approved/Rejected by',
          value: action.approvalUser ? formatUser(action.approvalUser) : '-',
        }, {
          label: 'Approved/Rejected on',
          value: action.approvalTime ? moment(action.approvalTime).format('DD/MM/YYYY') : '-',
        }
      ];

      that.keyValueDetails = keyValueDetails;

      const attrByName = (obj, type) => {
        const attribute = _.find(obj.attributes, attr => attr.type === type);
        return _.get(attribute, 'value', `<MISSING ${type}>`);
      };

      if (action.taskGroup === 'OPERATION') {
        const deferred = $q.defer();
        that.requestTimeout = deferred;
        that.subscription = Observable.fromPromise(
          fetchTaskDetails(action.id, {
            timeout: deferred.promise,
          }).toPromise())
          .map(operation => {
            keyValueDetails.push({
              label: 'Total amount',
              value: $filter('php')(action.amount),
            });

            return Object.assign(action, {
              operation,
            });
          })
          .mergeMap(action =>
            customerCache.profile(attrByName(action.operation, 'CUSTOMER_ID'))
              .toObservable()
              .combineLatest(productDefinitionService.toObservable(), (profile, definitions) => {
                action.customerProfile = profile;
                const p = _.find(definitions, {id: action.operation.productId});
                return Object.assign(action, {
                  product: p,
                  customerProfile: profile,
                });
              }))
          .do(action => {
            const customerData = action.customerProfile.individualData;
            keyValueDetails.push(...[{
                label: 'Customer name',
                value: formatUser(customerData),
              }, {
                label: 'Product name',
                value: _.get(action, 'product.productName', '<Unknown product>'),
              }]
            );
          })
          .subscribe();
      } else if (action.taskGroup === 'LEDGER_ACTION') {
        throw new Error("Not yet implemented");
      } else if (action.taskGroup === 'COMMAND_APPROVAL') {
        for (let property in action.description) {
          let info = action.description[property];

          let valuePromise = Promise.resolve(_.get(info, 'value'));
          if (property === 'CUSTOMER_BIRTHDAY') {
            valuePromise = valuePromise.then(v => v ? moment(v).format('DD/MM/YYYY') : '-');
          } else if (property === 'CUSTOMER_RISK_LEVEL') {
            valuePromise = valuePromise.then(v => {
              return new Promise((res, rej) =>
                dict.onLoadingComplete(() => res(dict.getDescription('CUSTOMER_RISK_LEVEL', v)))
              );
            });
          } else if (property === 'AMOUNT') {
            valuePromise = valuePromise.then(v => $filter('php')(v));
          }
          valuePromise.then(v => {
            keyValueDetails.push({
              label: property,
              value: v,
              translate: true
            })
          });
        }
      } else if (action.taskGroup === 'CUSTOMER') {
        const deferred = $q.defer();
        that.requestTimeout = deferred;
        that.subscription = Observable.fromPromise(
          fetchTaskDetails(action.id, {
            timeout: deferred.promise,
          }).toPromise())
          .subscribe(details => {
            const individualData = details.individualData;
            if (!individualData) {
              return;
            }

            keyValueDetails.push(...[{
              label: 'Customer name',
              value: formatUser(individualData),
            }, {
              label: 'Customer birthday',
              value: individualData.birthDate ? moment(individualData.birthDate).format('DD/MM/YYYY') : '-',
            }]);

            dict.onLoadingComplete(() => {
              keyValueDetails.push({
                label: 'Customer risk level',
                value: dict.getDescription('CUSTOMER_RISK_LEVEL', individualData.riskLevelId),
              });
            });
          });
      }
    };

    that.navigateToCustomerProfile = () => {
      let prospectId = that.action.objectId;
      if (that.action.name === 'CreateCustomer') {
        prospectId = _.get(that.action, 'commandDescriptor.context.prospectId');
      }
      $location.path(`/dashboard/actions/${that.action.id}/modes/${that.action.displayMode}/objects/${prospectId}/view-profile`)
    }


    that.$onDestroy = () => {
      if (that.requestTimeout) {
        that.requestTimeout.resolve(true);
      }

      if (that.subscription) {
        that.subscription.unsubscribe();
      }
    }
  }
});
