import module from 'module';
import $ from 'jquery';
import _ from 'lodash';
import {Subject} from 'rxjs/Subject'
import 'rxjs/add/operator/auditTime';

import './prints-details.style.less';
import templateUrl from './prints-details.template.html';
import {printParameterTranslation} from "constants/print";

module.component('printsDetails', {
  templateUrl,
  bindings: {
    printParameters: '<',
    print: '<'
  },
  controller: function ($location, $timeout, $scope, printsCache, confirmation, http, notification,
                        printService, $route, productDefinitionService) {
    const that = this;
    that.backgroundFiles = [];

    that.$onInit = () => {
      that.print.lines = (that.print.lines || []).sort((a, b) => a.field.localeCompare(b.field));
      that.print.lines.forEach(line => line.sampleText = line.field);

      $scope.print = that.print;
      if (that.print.backgroundFileIds) {
        that.backgroundFiles = that.print.backgroundFileIds.map(fileId => ({id: fileId}));
      }

      that.preview();
    };

    if(that.productDefinitionId) {
      productDefinitionService.toPromise()
        .then(defs => defs.find(def => String(def.id) === String(that.productDefinitionId)))
        .then(def => that.productDefinitionName = def.productName);
    }

    that.getPrintName = () => {
      if(!that.print) {
        return "";
      }

      let text = that.print.name;
      if(that.productDefinitionName) {
        text += ` for ${that.productDefinitionName}`;
      }

      return text;
    };

    that.printHasParams = () => {
      if(!that.print.parameters) {
        return false;
      }

      return Object.values(that.print.parameters).length > 0;
    };

    that.translatePrintParam = param => {
      return param in printParameterTranslation ? printParameterTranslation[param] : `<${param}>`;
    };

    that.$postLink = () => {
      that.printForm.$setSubmitted();
    };

    const backgroundFiles = () => {
      return that.backgroundFiles ? that.backgroundFiles : [];
    };

    const onLoad = () => {
      //Since I couldn't find a way to check if given pdf was rendered (iframe.onLoad is not enough), the
      //500 ms seems to be enough for chrome to render given pdf.  Without this there is visible blinking
      //during previews switching.
      const pdfRenderingTimeout = 500;
      $timeout(() => {
        let p = $('.prints-details__iframe-preview');
        $('.prints-details__iframe-hidden').toggleClass('prints-details__iframe-preview').toggleClass('prints-details__iframe-hidden');
        p.toggleClass('prints-details__iframe-preview').toggleClass('prints-details__iframe-hidden');
        $('.prints-details__iframe-hidden').one('load', onLoad);
      }, pdfRenderingTimeout);
    };
    $('.prints-details__iframe-hidden').one('load', onLoad);


    //Wait 500ms for change event. After given timeout has passed the preview action is called.
    const changeSubject = new Subject();
    const changeSub = changeSubject
      .auditTime(500)
      .subscribe(() => that.preview());

    that.onChange = () => {
      changeSubject.next();
    };

    $scope.$watch('$ctrl.backgroundFiles', () => that.onChange());

    that.getAttr = (attr) => {
      if (that.print) {
        return _.find(that.print.attributes, {type: attr});
      }
      return undefined;
    };

    const preparePdf = doc => {
      //make new copy of lines
      let lines = _.map(doc.lines, l => {
        l.text = l.sampleText || l.field;
        return l;
      });
      if (that.getAttr('MULTILINE_Y_SPACING') !== undefined) {
        //for multiline printing generate duplicate lines for better preview experience
        const defaultLineCount = 10;
        let lineCount = parseInt(that.getAttr('MULTILINE_LINE_COUNT').value) || defaultLineCount;
        let yOffset = parseInt(that.getAttr('MULTILINE_Y_SPACING').value);
        for(let i = 0; i < lineCount; i++) {
          for (let line of doc.lines) {
            let copy = _.cloneDeep(line);
            copy.y = line.y + yOffset * i;
            lines.push(copy);
          }
        }
      }

      const backgroundCount = backgroundFiles().length;
      const pageHeight = doc.orientation === 'PORTRAIT' ? doc.longEdge : doc.shortEdge;
      const pageCount = Math.max(1, backgroundCount, ...lines.map(line => 1 + Math.floor(line.y / pageHeight)));

      doc.pages = _.range(pageCount).map(page => {
        const pageStartHeight = page * pageHeight;
        const pageEndHeight = (page + 1) * pageHeight;
        const currentPageLines =
          lines.filter(line => line.y >= pageStartHeight - line.fontSize && line.y <= pageEndHeight + line.fontSize)
                .map(line => ({
                  ...line,
                  y: line.y - pageStartHeight
                }));

        return { lines: currentPageLines };
      });
      return doc;
    };

    that.preview = () => {
      if(!that.print) {
        return;
      }

      let print = preparePdf(that.print);
      printService.printToContainer(print, {
        autoPrint: false,
        backgroundThumbnails: backgroundFiles().map(file => file.thumbnail),
        backgroundEnabled: true,
        container: $('.prints-details__iframe-hidden')
      }, (pdf) => {
        let pdfSize = pdf.internal.pageSize;
        let ratio = pdfSize.width / pdfSize.height;
        $('#pdfWrapper').css('padding-bottom', (`${100/ratio}%`));
      });
    };

    that.cancel = () => {
      const goBack = () => $location.path('/admin/prints/');
      if (that.printForm.$dirty) {
        confirmation('Do you want to cancel? Canceling will discard all changes.', goBack);
      } else {
        goBack();
      }
    };

    that.save = () => {
      const updatePrint = (print) => {
        if(print.id) {
          return http.put(`/print/${print.id}`, print, {nxLoaderText: 'Updating print'});
        } else {
          return http.post(`/print`, print, {nxLoaderText: 'Updating print'});
        }
      };

      const successCb = (data) => {
        notification.show("Success", "Print updated successfully");
        printsCache.evict();
        $location.path("/admin/prints");
      };
      const errorCb = () => {
          notification.show("Error", "Failed to update print");
      };

      that.print.backgroundFileIds = backgroundFiles().map(file => file.id);
      updatePrint(that.print).success(successCb).error(errorCb);
    };

    that.$onDestroy = () => {
      changeSub.unsubscribe();
    };
  }

});
