import module from 'module';
import templateUrl from './denomination-serial-numbers.template.html';
import _ from 'lodash';

class DenominationSerialNumbers {
  constructor($scope, http, authentication) {
    this.$scope = $scope;
    this.http = http;
    this.authentication = authentication;
  }
  
  async $onInit() {
    const branchId = this.authentication.context.branchId;
    const branchSummary = await this.http.get(`/currencies/denomination/branches/${branchId}?currencyId=${this.currency.id}`, {}, {nxLoaderText: 'Loading serial numbers'}).toPromise();

    this.branchSummaryMap = {};
    branchSummary.summary.forEach(s => {
      this.branchSummaryMap[s.currencyUnitId] = s.serialNumbers == null ? [] : s.serialNumbers.map(serialNumber => ({serialNumber}));
    });

    this.noteList = _.reverse(_.sortBy(this.currency.units.filter(unit => unit.type === 'NOTE'), (a) => a.unitValue));

    this.serialNumberCashInConfig = {
      placeholder: 'Enter serial numbers',
      persist: false,
      createOnBlur: false,
      plugins: ['remove_button'],
      create: (input) => {
        input = input.toUpperCase();
        return {
          value: input,
          text: input
        };
      },
      createFilter: (serialNumber) => {
        let returnValue = true;
        this.noteList.forEach(note => {
          if (note.incomingSerialNumbers && note.incomingSerialNumbers.length) {
            note.incomingSerialNumbers.forEach(sn => {
              if (serialNumber.toUpperCase() === sn.toUpperCase()) {
                returnValue = false;
              }
            });
          }
        });
        
        return returnValue;
      }
    };

    this.serialNumberCashOutConfig = {
      valueField: 'serialNumber',
      labelField: 'serialNumber',
      searchField: ['serialNumber'],
      plugins: ['remove_button'],
      placeholder: 'Select serial numbers',
    };
  }
}

module.component('denominationSerialNumbers', {
  templateUrl: templateUrl,
  bindings: {
    'currency': '<',
    'objectRef': '=',
  },
  controller: DenominationSerialNumbers
});