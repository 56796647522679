import module from "module";
import _ from "lodash";
import BigNumber from "bignumber.js";
import {addAccountLabels} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./ledger-units.template.html');
module.component('ledgerUnits', {
  templateUrl,
  bindings: {
    // Reference to the units array held in outer scope
    'units': '=',
    // Reference to total amount of transaction (predefined unit value)
    'amount': '=',
    // Defines weather predefined unit is CREDIT or DEBIT
    'entryType': '<',
    // Fixed account label
    'fixedAccountLabel': '<',
    // Fixed units - if set user can not change or add other category
    'blockedUnits': '=',
    // Disable amount inputs
    'disableAmounts': '<'
  },
  controller: function ($scope, glMappingsService, systemPropertyCache) {

    const that = this;

    that.selectConfig = {
      placeholder: 'Select account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    that.supportsLedger = false;
    that.ledgerAccounts = [];
    that.userDefinedUnits = [];
    that.allUnits = [];

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
      that.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';
    });

    const ledgerAccountSub = glMappingsService.accounts.toObservable().subscribe(ledgerAccounts => {
      // Apply labels to accounts applicable for
      that.ledgerAccounts = addAccountLabels(ledgerAccounts);
    });

    that.onAmountChange = () => {
      that.amount = 0;
      if (that.userDefinedUnits && that.userDefinedUnits.length > 0) {
        that.amount = that.userDefinedUnits.reduce((sum, unit) => sum.add(unit['amount'] || 0), new BigNumber(0)).toNumber();
      }
    };

    that.addUnit = () => {
      const unitEntryType = that.entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT';
      that.userDefinedUnits.push({entryType: unitEntryType,
        amount: that.userDefinedUnits.length > 0 ? 0 : that.amount});
    };

    that.removeUnit = (unit) => {
      that.userDefinedUnits.splice(_.findIndex(that.userDefinedUnits, unit), 1);
      // In case of removal of last unit -> add empty one since at least 1 custom unit must be present
      if (!that.userDefinedUnits || that.userDefinedUnits.length === 0) that.addUnit();
      that.onAmountChange();
    };

    const syncUnits = () => {
      const predefinedUnit = {
        amount: that.amount,
        entryType: that.entryType
      };
      // Refresh referenced units collection
      if (that.blocked) {
        that.userDefinedUnits = that.blockedUnits;
      }
      that.units = _.union([predefinedUnit], that.userDefinedUnits);
    };

    $scope.$watch('$ctrl.userDefinedUnits', () => syncUnits(), true);

    $scope.$watch('$ctrl.blockedUnits', () => {
      if (that.blockedUnits && that.blockedUnits.length > 0) {
        that.blocked = true;
      } else {
        that.blocked = false;
      }
      syncUnits();
    });

    that.$onInit = () => {
      that.addUnit();
      syncUnits();
    };

    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
      ledgerAccountSub.unsubscribe();
    };
  }
});
