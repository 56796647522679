import module from "module";
import _ from "lodash";
import {addAccountLabels} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./funds-movement.template.html');
module.component('fundsMovement', {
  templateUrl: templateUrl,
  controller: function ($filter, $routeParams, $timeout, glMappingsService, actionCommand, http,
                        actionCategoryCache, systemPropertyCache, branchCurrencyCache, authentication, popup,
                        denominationSerialNumbersService) {

    let that = this;
    const actionType = _.get($routeParams, 'actionType');
    const cashInActionCodes = ['CASH_IN', 'ADJUSTMENT_IN', 'FOREX_CASH_IN'];
    const forexActionCodes = ['FOREX_CASH_IN', 'FOREX_CASH_OUT'];
    const actionCodesWithDenomination = ['CASH_IN', 'CASH_OUT', 'ADJUSTMENT_IN', 'ADJUSTMENT_OUT', 'FOREX_CASH_IN', 'FOREX_CASH_OUT'];

    that.actionCode = actionType.toUpperCase().replace(/-/g, '_');

    that.actionName = $filter("prettyEnum")(that.actionCode);
    that.useDenomination = actionCodesWithDenomination.includes(that.actionCode);
    that.isForexAction = forexActionCodes.includes(that.actionCode);
    that.group = cashInActionCodes.includes(that.actionCode) ? 'INCOMING' : 'OUTGOING';
    that.entryType = ['FOREX_CASH_IN', 'CASH_IN', 'ADJUSTMENT_IN'].includes(that.actionCode) ? 'DEBIT' : 'CREDIT';
    that.fixedAccountLabel = that.useDenomination ? 'User cash' : 'User check';
    that.transaction = {units: [], ledgerAmountInPhp: 0};
    that.blockedUnits = [];
    that.categories = [];
    that.remarksRequired = false;
    that.fixedAccount = false;
    that.ledgerAccounts = [];
    that.currencies = [];
    that.requireRecalculate = true;

    const ledgerAccountSub = glMappingsService.accounts.toObservable().subscribe(ledgerAccounts => {
      // Apply labels to accounts applicable for
      that.ledgerAccounts = addAccountLabels(ledgerAccounts);
    });

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
      const currencyProp = properties.find(prop => prop.code === 'MAIN_CURRENCY_ISO_CODE');
      
      that.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';
      that.mainCurrencyIso = currencyProp.value;
      that.canSaveNoteSerialNumber = _.find(properties, {code: 'FOREIGN_DENOMINATION_SERIAL_NUMBERS_ENABLED'}).value === 'TRUE';
    });

    // On init set form as submitted to highlight invalid fields
    $timeout(() => {
      that.transactionForm.$setSubmitted();
    });

    const categoriesSub = actionCategoryCache.toObservable().subscribe(categories => {
      that.categories = categories.filter(cat => cat.actionType === that.actionCode);
    });

    that.onCategoryChange = () => {
      that.requireRecalculate = true;
      that.remarksRequired = false;
      if (that.transaction.categoryId) {
        const category = _.find(that.categories, {id: that.transaction.categoryId});

        if (category.supportedCurrencyIds == null) {
          setDefaultForeignCurrencies();
        } else {
          that.foreignCurrencies = that.currencies
            .filter(currency => currency.isoCode !== that.mainCurrencyIso && category.supportedCurrencyIds.find(id => id === currency.id));
        }

        if (category.ledgerAccountFullCode) {
          let fixedAccount = _.find(that.ledgerAccounts, {fullCode: category.ledgerAccountFullCode});
          that.blockedUnits = [
            {
              accountCode: fixedAccount.fullCode,
              amount: that.transaction.amount,
              entryType: that.entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT'
            }
          ];
        } else {
          that.blockedUnits = [];
        }
        that.remarksRequired = category && category.remarksRequired;
      }
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.onCurrencyChange = () => {
      denominationSerialNumbersService.resetSerialNumbers(that.currency);
      that.currency = that.foreignCurrencies.find(cur => cur.id === that.currencyId);
      that.transaction.currencyId = that.currencyId;
    };

    that.denominationIsValid = () => {
      return !that.useDenomination || (that.transaction.denominationBundle != null && that.transaction.denominationValid);
    };

    that.save = () => {
      delete that.transaction["currencyId"];
      denominationSerialNumbersService.appendSerialNumbers(that.currency, that.transaction);
      actionCommand.executeAndShowValidation(that.actionCode, that.transaction);
    };

    that.isDenominationReady = () => {
      if (that.isForexAction) {
        return that.currencyId != null;
      }
      return true;
    }

    that.onAmountChange = () => {
      that.requireRecalculate = true;
    }

    that.calculateExchangeAmount = async () => {
      const response = await http.post("/forex/system-exchange/calculate", that.transaction.denominationBundle).toPromise();
      that.transaction.ledgerAmountInPhp = response;
      that.requireRecalculate = false;
    }

    const setDefaultForeignCurrencies = () => {
      that.foreignCurrencies = that.currencies.filter(c => c.isoCode !== that.mainCurrencyIso);
    }

    that.$onInit = async () => {
      that.amountInputOrder = that.isForexAction ? 3 : 2;
      await fetchCurrencies();
    };

    const fetchCurrencies = async () => {
      const currencies = await branchCurrencyCache.withParam(Number(authentication.context.branchId)).toPromise();
      that.currencies = currencies;

      // initially, when no currency is selected the display for currency will be the main currency isoCode by default
      that.currency = currencies.find(cur => cur.isoCode === that.mainCurrencyIso);
      setDefaultForeignCurrencies();
    };

    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
      categoriesSub.unsubscribe();
      ledgerAccountSub.unsubscribe();
    };
  }
});
