import module from 'module';
import _ from 'lodash';
import $ from 'jquery';

const templateUrl = require('./new-customer.template.html');
module.component('newCustomer', {
  templateUrl: templateUrl,
  controller: function ($scope, http, $timeout, $location, dict, notification, propertyConfigService,
                        authentication, systemPropertyCache, modalPrintPreviewService, command, popup) {
    let that = this;
    that.cfg = propertyConfigService;


    that.organizationType = null;
    that.singlePage = null;
    that.corporateCustomerEnabled = null;
    that.defaultUrl = null;

    that.customerTypes = [
      {id: 'INDIVIDUAL', description: 'Individual customer'},
      {id: 'CORPORATE', description: 'Corporate customer'}
    ];

    that.forms = [that.individualDataForm, that.corporateDataForm, that.documentsForm, that.addressesForm, that.incomeForm, that.depositoryForm, that.relatedPeopleForm];

    // The model must have the same structure as the original model
    that.profile = {
      customerType: 'INDIVIDUAL',

      individualData: {
        pep: false,
        vip: false,
        employee: false,
        dosri: false
      },

      corporateData: {},
      externalAccounts: [],

      addresses: [{}],
      idDocuments: [{}],
      incomeSources: [{}],
      relatives: [],
      extractedPhones: {}
    };

    const clonedModel = $.extend(true, {}, that.profile);

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
      that.organizationType = organizationType === null ? 'BANK' : organizationType.value;
      const singlePageProp = _.find(properties, {code: 'CIF_SINGLE_PAGE'});
      that.singlePage = singlePageProp && singlePageProp.value === 'TRUE';
      const corpoProp = _.find(properties, {code: 'CIF_CORPORATE_SUPPORT'});
      that.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';
      const defaultUrl = _.find(properties, {code: 'DEFAULT_URL_AFTER_CST_LOOKUP'});
      that.defaultUrl = defaultUrl && defaultUrl.value ? defaultUrl.value : '/customer/{}/accounts';
    });

    dict.onLoadingComplete(() => {
      const defaultRiskLevel = dict.getId('CUSTOMER_RISK_LEVEL', 'LOW');
      that.profile.individualData.riskLevelId = clonedModel.individualData.riskLevelId = defaultRiskLevel;
      that.profile.corporateData.riskLevelId = clonedModel.corporateData.riskLevelId = defaultRiskLevel;

      const ph = dict.getId('COUNTRY', 'PH');
      that.profile.individualData.citizenshipId = clonedModel.individualData.citizenshipId = ph;
      that.profile.individualData.residenceId = clonedModel.individualData.residenceId = ph;

      // FIXME = default values for country codes should be fetched from [cfg]
      that.profile.addresses[0].countryId = ph;
      that.profile.idDocuments[0].countryId = ph;
    });

    let getProfile = () => {
      return that.profile;
    };

    $timeout(() => {
      // show validation problems on form:
      that.forms.forEach(form => {
          if (form) form.$setSubmitted();
        })
    });

    that.reset = (forceReset) => {
      if (forceReset || confirm("Do you really want to reset all fields?")) {
        that.profile = $.extend(true, {}, clonedModel);

        that.forms.forEach(form => {
            if (form) form.$setPristine();
          })
      }
    };

    let prepareCustomerForSaving = (profile) => {
      if (profile.customerType == 'INDIVIDUAL') {
        profile.corporateData = null;
        // signature
        let signatureFileId = _.head(profile.individualData.signatureFile);
        if (signatureFileId && signatureFileId.uploaded) {
          profile.individualData.signatureFileId = signatureFileId.id;
        }

        // profile picture
        let pictureFileId = _.head(profile.signatureFile);
        if (pictureFileId && pictureFileId.uploaded) {
          profile.pictureFileId = pictureFileId.id;
        }
      } else {
        profile.individualData = null;
        if(that.organizationType == 'PAWNSHOP') { //&& CORPORATE from surrounding if
          profile.idDocuments = [];
        }
      }

      profile.branchId = authentication.context.branchId;
      profile.userId = authentication.context.id;
      profile.status = 'PROSPECT';
      profile.verified = true;

      // sets "primary" attributes
      for (let i = 0; i < profile.idDocuments.length; i++) {
        profile.idDocuments[i].primary = (i === 0);
        profile.idDocuments[i].orderNo = i;
        profile.idDocuments[i].photoFileIds = profile.idDocuments[i].photos.map(photo => photo.id);
        delete profile.idDocuments[i].photos;
      }

      for (let i = 0; i < profile.addresses.length; i++) {
        profile.addresses[i].primary = (i == 0);
        profile.addresses[i].orderNo = i;
      }

      for (let i = 0; i < profile.incomeSources.length; i++) {
        profile.incomeSources[i].primary = (i == 0);
        profile.incomeSources[i].orderNo = i;
        if (profile.incomeSources[i].companyAddress) profile.incomeSources[i].companyAddress.primary = true;
      }

      if (profile.individualData) {
        delete profile.individualData.signatureFile;
        delete profile.signatureFile;
      }


      // phones
      profile.phones = [];
      let phonePush = (phone, type) => {
        if (phone && phone.number && type) {
          profile.phones.push({
            type: type,
            number: phone.number,
            primary: false
          });
        }
      };

      phonePush(profile.extractedPhones.home, 'HOME');
      phonePush(profile.extractedPhones.work, 'WORK');
      phonePush(profile.extractedPhones.mobile, 'MOBILE');
    };

    /**
     * After successful customer creation user should be redirected to other page.
     *
     * For PAWNSHOP -> redirect to new pawn
     * For BANK -> redirect to actions
     */
    const performRedirect = (customerId) => {
      http.get(`/customers/${customerId}`)
        .success(() => {
          let redirectionUrl = that.defaultUrl.replace('{}', customerId);
          $location.path(redirectionUrl);
          // if customer is prospect => 404 will be returned
        }).error(redirectBack)

    };

    const redirectBack = () => $location.path('/dashboard/lookup-customer')

    that.saveCustomer =  () => {
      const profile = _.cloneDeep(getProfile());
      prepareCustomerForSaving(profile);
      command.execute('CreateCustomer', profile)
        .success((res) => {
          let customerId = res.output.id;
          const onComplete = () => {
            that.reset(true);
            performRedirect(customerId);
            notification.show('Success', 'Customer profile created successfully.');
          };
          if (profile.customerType === 'INDIVIDUAL') {
            modalPrintPreviewService.show('SIGNATURE_CARD', {customerId: customerId}, () => {
              onComplete();
            });
          } else {
            onComplete();
          }
        });
    };

    that.$onDestroy = () => systemPropertiesSub.unsubscribe();
  }
});
