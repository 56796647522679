import module from 'module';
import _ from 'lodash';
import {addAccountLabels} from '../../../general-ledger/common/gl.utils';

import './layaway-payment-view.style.less';

import templateUrl from './layaway-payment-view.template.html';

class LayawayPaymentView {
  constructor ($routeParams, $location, pawnItemCache, pawnItemTypeCache, authentication, notification, command,
               confirmationTemplate, actionCategoryCache, systemPropertyCache, glMappingsService, branchService,
               $filter, customerCache, confirmation, http) {
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.branchService = branchService;
    this.$filter = $filter;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.http = http;
  }

  async $onInit() {
    this.item = {};

    this.amount = 0;

    this.customerId = parseInt(this.$routeParams.customerId);
    const layawayId = parseInt(this.$routeParams.layawayId);
    this.request = {
      denominationBundle: null,
      remarks: null,
      customerId: null
    };

    this.layaway = await this.http.get(`/products/layaways/${layawayId}`).toPromise();

    const [properties, categories, ledgerAccounts] = await Promise.all([
      this.systemPropertyCache.toPromise(),
      this.actionCategoryCache.toPromise(),
      this.glMappingsService.accounts.toPromise()
    ]);

    const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
    this.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';

    // Apply labels to accounts applicable for
    this.ledgerAccounts = addAccountLabels(ledgerAccounts);

    this.categories = categories.filter(cat => cat.actionType === 'CASH_IN' && cat.code === 'SELL_PAWN_ITEM');
    // Select the first and only category
    this.transaction = {
      categoryId: this.categories[0].id
    }
    this.onCategoryChange();
  }

  onCategoryChange() {
    this.remarksRequired = false;
    if (this.transaction.categoryId) {
      const category = _.find(this.categories, {id: this.transaction.categoryId});
      this.remarksRequired = category && category.remarksRequired;

      if (!this.supportsLedger) {
        // no need to fill units
        return;
      }

      if (category.ledgerAccountFullCode) {
        const fixedAccount = _.find(this.ledgerAccounts, {fullCode: category.ledgerAccountFullCode});

        this.blockedUnits = [
          {
            accountCode: fixedAccount.fullCode,
            amount: this.request.amount,
            entryType: 'CREDIT'
          }
        ];
      } else {
        this.blockedUnits = [];
      }
    }
  };

  onAmountChange() {
    this.onCategoryChange();
  }

  goBack() {
    this.$location.path(`/customer/${this.customerId}/layaways/${this.layaway.id}`);
  }

  async pay() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to proceed?`,
      details: [
        {label: 'Transaction category', description: this.categories.find(c => c.id === this.transaction.categoryId).name},
        {label: 'Amount', description: this.$filter('php')(this.request.amount)},
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      productId: this.layaway.id,
      customerId: this.layaway.customerId,
      branchId: this.layaway.branchId,
      units: this.supportsLedger ? this.transaction.units : null
    };

    const response = await this.command.execute('PayLayaway', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.customerCache.layaways(this.layaway.customerId).evict();
    this.$location.path(`/customer/${this.layaway.customerId}/layaways/`);
  }
}

module.component('layawayPaymentView', {
  templateUrl: templateUrl,
  controller: LayawayPaymentView
});
