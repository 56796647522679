import module from 'module';
import _ from 'lodash';
import {addAccountLabels} from '../../../general-ledger/common/gl.utils';

import './layaway-cancel-view.style.less';

import templateUrl from './layaway-cancel-view.template.html';
import BigNumber from "bignumber.js";

class LayawayCancelView {
  constructor ($routeParams, $location, pawnItemCache, pawnItemTypeCache, authentication, notification, command,
               confirmationTemplate, actionCategoryCache, systemPropertyCache, glMappingsService, branchService,
               $filter, customerCache, confirmation, http) {
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.branchService = branchService;
    this.$filter = $filter;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.http = http;
  }

  async $onInit() {
    const layawayId = parseInt(this.$routeParams.layawayId);
    this.request = {
      denominationBundle: null,
      refund: 0
    };

    this.layaway = await this.http.get(`/products/layaways/${layawayId}`).toPromise();
    this.layaway.accumulatedPayments = this.layaway.sellingPrice - this.layaway.balance;

    if (this.layaway.accumulatedPayments >= this.layaway.minimalPaymentsForRefund) {
      this.request.refund = new BigNumber(this.layaway.accumulatedPayments)
        .div(2)
        .toNumber();
    }

    const [properties, branches, categories, ledgerAccounts] = await Promise.all([
      this.systemPropertyCache.toPromise(),
      this.branchService.toPromise(),
      this.actionCategoryCache.toPromise(),
      this.glMappingsService.accounts.toPromise()
    ]);

    const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
    this.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';

    // Apply labels to accounts applicable for
    this.ledgerAccounts = addAccountLabels(ledgerAccounts);

      if (this.layaway && this.layaway.id) {
        const [customer, branches] = await Promise.all([
          this.customerCache.profile(this.layaway.customerId).toPromise(),
          this.branchService.toPromise()
        ]);

        this.customer = {
          ...customer,
          name: customer.effectiveName,
          birthDate: customer.individualData.birthDate,
          branch: branches.find(b => b.id === customer.branchId)
        };

        this.customer.customerId = this.customer.id
      }

    this.categories = categories.filter(cat => cat.actionType === 'CASH_OUT' && cat.code === 'LAYAWAY_CANCEL');
    // Select the first and only category
    this.transaction = {
      categoryId: this.categories[0].id
    }
    this.onCategoryChange();
  }

  onCategoryChange() {
    this.remarksRequired = false;
    if (this.transaction.categoryId) {
      const category = _.find(this.categories, {id: this.transaction.categoryId});
      this.remarksRequired = category && category.remarksRequired;

      if (!this.supportsLedger) {
        // no need to fill units
        return;
      }

      if (category.ledgerAccountFullCode) {
        const fixedAccount = _.find(this.ledgerAccounts, {fullCode: category.ledgerAccountFullCode});

        this.blockedUnits = [
          {
            accountCode: fixedAccount.fullCode,
            amount: this.request.refund,
            entryType: 'DEBIT'
          }
        ];
      } else {
        this.blockedUnits = [];
      }
    }
  };

  goBack() {
    this.$location.path(`/customer/${this.customer.customerId}/layaways/${this.layaway.id}`);
  }

  async cancel() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to cancel this layaway?`,
      details: [
        {label: 'Transaction category', description: this.categories.find(c => c.id === this.transaction.categoryId).name},
        {label: 'Refund', description: this.$filter('php')(this.request.refund)}
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      productId: this.layaway.id,
      customerId: this.layaway.customerId,
      branchId: this.layaway.branchId,
      units: this.supportsLedger ? this.transaction.units : null
    };

    const response = await this.command.execute('CancelLayaway', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict();
    await this.customerCache.layaways(this.customer.customerId).refetch();
    this.$location.path(`/customer/${this.customer.customerId}/layaways/`);
  }
}

module.component('layawayCancelView', {
  templateUrl: templateUrl,
  controller: LayawayCancelView
});
