import module from 'module';
import './pawn-product-general.style.less';

const templateUrl = require('./pawn-product-general.template.html');

class PawnProductGeneralForm {
  constructor(pawnTagCache, pawnProductsCache) {
    this.pawnTagCache = pawnTagCache;
    this.pawnProductsCache = pawnProductsCache
  }

  async $onInit() {
    const [tags, pawnProducts] = await Promise.all([
      this.pawnTagCache.toPromise(),
      this.pawnProductsCache.toPromise()
    ]);

    if (!this.pawnProduct || !this.pawnProduct.id) {
      pawnProducts.unshift({
        id: -1,
        productDefinition: {
          productName: "This"
        }
      });
    }

    this.tags = tags;
    this.pawnProducts = pawnProducts;

    this.termIntervals = [
      {
        value: 'DAILY',
        label: 'Daily'
      },
      {
        value: 'MONTHLY',
        label: 'Monthly'
      }
    ];

    this.onExpirationOptions = [
      {
        value: 'PAST_DUE_NON_PERFORMING',
        label: 'Expire the pawn and its items for later withdrawal at any time'
      },
      {
        value: 'STOCKED',
        label: 'Expire the pawn and its items for later withdrawal unless it\'s a working day then move to stock automatically'
      },
      {
        value: 'AUCTIONED',
        label: 'Make the pawn auctioned and move its items to stock at any time'
      }
    ]
  }

  getTermIntervalAppendLabel() {
    return this.pawnProduct.termInterval === 'DAILY' ? 'days' : 'months';
  }

}

module.component('pawnProductGeneralForm', {
  templateUrl,
  bindings: {
    pawnProduct: '=',
    form: '=',
    createMode: '<',
    branches: '<',
    pawnItemCategories: '<'
  },
  controller: PawnProductGeneralForm
});