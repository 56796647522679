import module from 'module';
import csv from "jquery-csv";

const templateUrl = require('./batch-upsert-pawn-auction.template.html');

module.component('batchUpsertPawnAuction', {
  templateUrl,
  controller: function ($filter, $route, command, confirmationTemplate) {

    const that = this;
    const headers = ['branchCode', 'date', 'pawnReleaseFrom', 'pawnReleaseTo'];

    that.auctionName = null;
    that.csvContent = null;
    that.csvSeparator = ';';

    that.separators = [
      {value: ';', label: 'Semicolon (;)'},
      {value: ',', label: 'Comma (,)'}
    ];

    const formatCommand = () => {
      if (!that.csvContent) return {};
      const header = headers.join(that.csvSeparator) + '\n';
      const rows = csv.toObjects(header + that.csvContent, {'separator': that.csvSeparator});
      return {auctionName: that.auctionName, branchRows: rows}
    };

    that.execute = () => confirmationTemplate({
      question: 'Do you want to create/update pawn auctions?',
      warning: 'Existing auctions with the same name will be overwritten',
      yesCallback: () => command.execute('BatchUpsertPawnAuction', formatCommand()).success(() => $route.reload())
    });
  }
});
