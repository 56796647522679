import module from 'module';
import _ from 'lodash';

const templateUrl = require('./documents.template.html');
module.component('customerProfileDocuments', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '='
  },
  controller: function ($scope, dict, propertyConfigService, systemPropertyCache) {
    let that = this;
    that.dict = dict;
    that.cfg = propertyConfigService;
    let gsisDictId = null;
    let tinDictId = null;

    $scope.$watch('$ctrl.profile.idDocuments', () => {
      // Show expiration field if required
      _.forEach(that.profile.idDocuments, doc => {

        doc.expirationSupported = isExpirationSupported(doc.documentTypeId);

        // Set document photo file ids
        doc.photos = doc.photos || [];
        doc.photoFileIds = doc.photoFileIds || [];
        doc.photoFileIds.forEach((photoId) => {
          if (!doc.photos.some(photo => photo.id === photoId)) {
            doc.photos.push({id: photoId});
          }
        })
      });
    });

    that.hasAtLeastOneDocument = () => {
      return that.profile.idDocuments.length > 0;
    };

    systemPropertyCache.toObservable().first().subscribe(properties => {
      const minDocs = _.find(properties, {code: 'CIF_MIN_DOCUMENT_SECTIONS'});
      that.minDocsSections = minDocs ? Number(minDocs.value) : 0;
    });

    dict.onLoadingComplete(() => {      
      that.idDocumentType = dict['ID_DOCUMENT_TYPE'];
      that.idDocumentType.forEach(idDocument => {
        let documentGroup = 'Primary ID';
        const priorityValue = idDocument.attributes['priority'][0];

        if (priorityValue != 1) {
          documentGroup = 'Secondary ID';
        }

        idDocument.documentGroup = documentGroup;
      });

      gsisDictId = dict.getId('ID_DOCUMENT_TYPE', 'GSIS_SSS');
      tinDictId = dict.getId('ID_DOCUMENT_TYPE', 'TIN');
    });

    that.addDocument = function () {
      let docs = that.profile.idDocuments;
      // mark the first document as primary
      let primary = false;
      if (docs === undefined) {
        docs = that.profile.idDocuments = [];
        primary = true;
      }
      docs.push({
        dateSubmitted: new Date().getTime(),
        countryId: that.readDefaultCountry(),
        primary: primary
      });
    };

    that.removeDocument = function (doc) {
      let array = that.profile.idDocuments;
      let index = array.indexOf(doc);
      array.splice(index, 1);
    };

    const isExpirationSupported = (docTypeId) => {
      const dict = that.dict['ID_DOCUMENT_TYPE'];
      if (dict && dict.entries) {
        const entry = _.find(dict, {id: docTypeId});
        if (entry && entry.attributes) {
          return entry.attributes['supportsExpiration'][0];
        }
      }
      return false;
    };

    that.documentTypeChanged = function (document) {
      document.expirationSupported = isExpirationSupported(document.documentTypeId);
      document.expirationDate = null;
      document.documentNumber = null;
      document.issuingAuthority = null;
      document.countryId = that.readDefaultCountry();
    };

    that.readDefaultCountry = () => {
      let defaultCountryCode = that.cfg.defaultValue('customer', 'document', 'issuing_country');
      if (defaultCountryCode) {
        let country = _.find(that.dict['COUNTRY'], {code: defaultCountryCode});
        return country && country.id ? country.id : null;
      }
      return null;
    };
  }
});
