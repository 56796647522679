import module from 'module';
import _ from 'lodash';
import templateUrl from './forex-interbranch-cash-transfer.template.html';

class ForexInterBranchCashTransfer {
  constructor(branchServiceHelper, authentication, branchCurrencyCache, systemPropertyCache, actionCommand, confirmation,
              $filter, http, denominationSerialNumbersService) {
    this.branchServiceHelper = branchServiceHelper;
    this.authentication = authentication;
    this.branchCurrencyCache = branchCurrencyCache;
    this.systemPropertyCache = systemPropertyCache;
    this.actionCommand = actionCommand;
    this.confirmation = confirmation;
    this.$filter = $filter;
    this.http = http;
    this.denominationSerialNumbersService = denominationSerialNumbersService;

    this.transaction = {};
  }

  async $onInit() {
    const [branches, currencies, systemProperties] = await Promise.all([
      this.branchServiceHelper.getOperatingBranches(),
      this.branchCurrencyCache.withParam(Number(this.authentication.context.branchId)).toPromise(),
      this.systemPropertyCache.toPromise()]);

    this.branches = _.sortBy(branches, 'name');
    this.sourceBranch = this.branches.find(b => b.id === this.authentication.context.branchId);
    this.transaction.sourceBranchId = this.sourceBranch.id;

    const mainCurrencyIso = systemProperties.find(prop => prop.code === 'MAIN_CURRENCY_ISO_CODE');
    this.foreignCurrencies = currencies.filter(c => c.isoCode !== mainCurrencyIso.value)

    this.canSaveNoteSerialNumber = _.find(systemProperties, {code: 'FOREIGN_DENOMINATION_SERIAL_NUMBERS_ENABLED'}).value === 'TRUE';
  }

  onCurrencyChange() {
    if (this.currency) {
      this.denominationSerialNumbersService.resetSerialNumbers(this.currency);
    }
    
    this.currency = this.foreignCurrencies.find(cur => cur.id === this.transaction.currencyId);
    this.transaction.amount = null;
  }

  getTargetBranchName() {
    const maybeBranch = this.branches.find(b => b.id === this.transaction.targetBranchId);
    return maybeBranch ? maybeBranch.name : '';
  }

  denominationIsValid() {
    return this.transaction.denominationBundle != null && this.transaction.denominationValid;
  };

  async save() {
    this.denominationSerialNumbersService.appendSerialNumbers(this.currency, this.transaction);

    if (await this.confirmation(`Do you want to transfer ${this.$filter('php')(this.transaction.amount, {currency: this.currency.isoCode})} 
                                 to branch ${this.getTargetBranchName()}?`)) {
      await this.actionCommand.execute('FOREX_INTERBRANCH_CASH_TRANSFER', this.transaction).toPromise();
    }
  }
}

module.component('forexInterbranchCashTransfer', {
  templateUrl,
  controller: ForexInterBranchCashTransfer
});
