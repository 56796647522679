import module from 'module';

const templateUrl = require('./corporate-data.template.html');
module.component('customerProfileCorporateData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '='
  },
  controller: function ($scope, $timeout, dict, propertyConfigService) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;
  }
});
