import module from 'module';
import _ from 'lodash';
import CompositeRequest from 'shared/common/compositeRequest';

module.factory('profileUpdateService', function (http, customerService, command) {

  function updatePhoneData(profile, type, number) {
    let phoneType = _.find(profile.phones, {type: type});
    if(number) {
      if (phoneType && phoneType.id) {
        //update existing
        phoneType.number = number;
      } else {
        //add phone
        profile.phones.push({
          number: number,
          type: type,
          primary: false
        });
      }
    } else {
      _.remove(profile.phones, {type: type});
    }
  }

  /**
   * if profile and original is not equal return updated profile data in format acceptable by server
   * otherwise return null
   */
  function prepareRequest(profile, original) {

    const shallowProfileClone = _.clone(profile);
    const shallowOriginalClone = _.clone(original);

    const extractFilesIdsAndRemoveFileContent = (fileHolder, filesKey) => {
      let filesModels = fileHolder[filesKey];
      filesModels = filesModels || [];
      //cleanup model to make sure data will not be send back to server
      //we only care about the file id
      fileHolder[filesKey] = null;
      return filesModels.filter(f => f.id)
        .map(f => f.id);
    }


    if (!_.isEqual(shallowProfileClone, shallowOriginalClone)) {
      updatePhoneData(shallowProfileClone, 'HOME', _.get(shallowProfileClone, 'extractedPhones.home.number'));
      updatePhoneData(shallowProfileClone, 'MOBILE', _.get(shallowProfileClone, 'extractedPhones.mobile.number'));
      updatePhoneData(shallowProfileClone, 'WORK', _.get(shallowProfileClone, 'extractedPhones.work.number'));

      shallowProfileClone['extractedPhones'] = null;
      shallowOriginalClone['extractedPhones'] = null;

      if(shallowProfileClone.customerType === 'INDIVIDUAL') {
        let signatureFileId = _.head(extractFilesIdsAndRemoveFileContent(shallowProfileClone.individualData, 'signatureFile'));
        shallowProfileClone.individualData.signatureFileId = signatureFileId;

        let pictureFileId = _.head(extractFilesIdsAndRemoveFileContent(shallowProfileClone, 'pictureFile'));
        shallowProfileClone.pictureFileId = pictureFileId;

        shallowProfileClone.idDocuments.forEach(function (doc) {
          doc.photoFileIds = extractFilesIdsAndRemoveFileContent(doc, 'photos');
        });
      }
      return shallowProfileClone;
    }
    return null;
  }

  return {
    update: (profile, original) => {
      let customerData = prepareRequest(profile, original);
      if (customerData) {
        return command.execute('UpdateCustomer', customerData);
      } else {
        return null;
      }
    }
  }
});
