import module from 'module';
import _ from 'lodash';

import './risk-category-details.style.less';
import templateUrl from './risk-category-details.template.html';

class RiskCategoryDetailsView {
  constructor(http, $route, breadcrumbs, confirmation, $location, dict, command, systemPropertyCache) {
    this.http = http;
    this.$route = $route;
    this.breadcrumbs = breadcrumbs;
    this.confirmation = confirmation;
    this.$location = $location;
    this.dict = dict;
    this.command = command;
    this.systemPropertyCache = systemPropertyCache;
  }

  async fetchSelectedCategory() {
    this.category = await this.http.get(`/management/risk/categories/${this.categoryId}`).toPromise();
    this.calculateEntryScores();

    _.set(this.breadcrumbs, 'options.risk-category-label', (this.category || {}).name);
  }

  calculateEntryScores() {
    if (!this.category) {
      return;
    }

    this.category.entries = this.category.entries.map(
      e => ({
        ...e,
        score: this.calculateScore(e)
      })
    );
  }

  async $onInit() {
    [this.properties, this.propertyDefinitions] = await Promise.all([
      this.systemPropertyCache.toPromise(),
      this.http.get('/management/risk/categories/property-definitions').toPromise()
    ]);

    const corpoProp = _.find(this.properties, {code: 'CIF_CORPORATE_SUPPORT'});
    this.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';

    this.dict.onLoadingComplete(() => {
      this.highRiskDictionaryEntryId = this.dict.getId('CUSTOMER_RISK_LEVEL', 'HIGH');
      this.riskDictionaryEntries = this.dict['CUSTOMER_RISK_LEVEL'];
    });

    this.categoryId = this.$route.current.params['riskCategoryId'];

    await this.initializeCategory();
    this.setAvailableCustomerTypes();
  }

  async initializeCategory() {
    if (this.categoryId) {
      await this.fetchSelectedCategory();
    } else {
      this.category = {
        entries: [{
          conditions: {}
        }]
      };
    }
  }

  setAvailableCustomerTypes() {
    this.availableCustomerTypes = [
      {
        value: 'INDIVIDUAL',
        label: 'Individual'
      }
    ];

    if (this.corporateCustomerEnabled) {
      this.availableCustomerTypes.push(
        {
          value: 'CORPORATE',
          label: 'Corporate'
        }
      )
    }
  }

  isMainSaveDisabled() {
    return this.form.$invalid || this.form.$pristine;
  }

  getForm(index) {
    return this[`form_${index}`];
  }

  isSaveDisabled(index) {
    return this.getForm(index).$invalid || this.getForm(index).$pristine || this.getForm(index).$submitted
  }

  isAutoHighRiskDisabled(e) {
    return e.riskDictionaryEntryId !== this.highRiskDictionaryEntryId;
  }

  onRiskLevelChange(e) {
    if (this.isAutoHighRiskDisabled(e)) {
      e.autoHighRisk = false;
    }
  }

  async removeCategory() {
    const confirmation = await this.confirmation('Do you want to remove this risk category?');
    if (!confirmation) {
      return;
    }

    const response = await this.command.execute('DeleteRiskCategory', {id: this.categoryId}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.goBack();
  }

  async upsertCategory(action, command) {
    const confirmation = await this.confirmation(`Do you want to ${action} following risk category?`);
    if (!confirmation) {
      return;
    }

    const response = await this.command.execute(command, {category: this.category}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$location.path(`/admin/compliance/risk/categories/${response.output.id}`);
    this.$route.reload();
  }

  goBack() {
    this.$location.path(`/admin/compliance/risk/categories/`);
  }

  addEntry() {
    this.category.entries.push({
      riskCategoryId: this.category.id,
      conditions: {},
      autoHighRisk: false
    });
  }

  async removeEntry(idx) {
    const entry = this.category.entries[idx];
    if (entry.id) {
      const confirmation = await this.confirmation('Do you want to remove this entry?');
      if (!confirmation) {
        return;
      }

      const response = await this.command.execute('DeleteRiskCategoryEntry', {id: entry.id}).toPromise();
      if (response.approvalRequired) {
        return;
      }
    }

    await this.fetchSelectedCategory();
  }

  async upsertEntry(idx, action, command) {
    const confirmation = await this.confirmation(`Do you want to ${action} this risk category entry?`);
    if (!confirmation) {
      return;
    }

    const entry = _.cloneDeep(this.category.entries[idx]);
    entry.conditions = {json: JSON.stringify(entry.conditions)};

    const response = await this.command.execute(command, {entry: entry}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.getForm(idx).$setSubmitted();
    await this.fetchSelectedCategory();
  }

  onWeightChange(idx) {
    const entry = this.category.entries[idx];
    entry.score = this.calculateScore(entry);
  }

  calculateScore(entry) {
    return this.category.weightPercentage / 100.0 * entry.weight;
  }

  addCondition(entry, propertyDefinition) {
    if (entry.conditions[propertyDefinition.property]) return; // first selected property is used as a key, results in having only one such property for whole condition

    entry.conditions[propertyDefinition.property] = {properties: [propertyDefinition.property]};
  }
}

module.component('riskCategoryDetailsView', {
  templateUrl,
  controller: RiskCategoryDetailsView
});
