import module from 'module';
import $ from 'jquery';
import 'components/customer/profile/profile-update.service';
import _ from "lodash";

const templateUrl = require('./profile.template.html');
module.component('customerProfile', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $timeout, dict, customerCache, profileUpdateService, systemPropertyCache, $location, confirmation) {
    const that = this;

    that.singlePage = true;
    that.corporateCustomerEnabled = false;

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const singlePageProp = _.find(properties, {code: 'CIF_SINGLE_PAGE'});
      that.singlePage = singlePageProp && singlePageProp.value === 'TRUE';
      const corpoProp = _.find(properties, {code: 'CIF_CORPORATE_SUPPORT'});
      that.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';
      const organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
      that.organizationType = organizationType === null ? 'BANK' : organizationType.value;
    });

    $scope.profile = {};
    $scope.dict = dict;
    $scope.editMode = false;
   
    $scope.clonedCustomerProfileModel = null;

    let customerId = $route.current.params['customerId'];
    customerCache.profile(customerId).toObservable().subscribe(profile => {
      $scope.profile = profile;
    });

    that.mergeCustomers = () => {
      $location.path(`/customer/${customerId}/profile/merge`);
    };

    $scope.enableEditMode = function () {
      $scope.editMode = true;
      $scope.clonedCustomerProfileModel = $.extend(true, {}, $scope.profile);
//      clearFormDirty();
    };

    $scope.disableEditMode = function () {
      $scope.editMode = false;
    };

    $scope.cancelEdit = function () {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
        $scope.profile = $scope.clonedCustomerProfileModel;
        $scope.disableEditMode();
        $location.path($location.path())
      });
    };

    $timeout(() => {
      // show validation problems on form:
      [that.individualDataForm, that.corporateDataForm, that.documentsForm, that.addressesForm, that.incomeForm, that.relatedPeopleForm]
        .forEach(form => {
          if (form) form.$setSubmitted();
        })
    });

    function clearFormDirty() {
      [that.individualDataForm, that.corporateDataForm, that.documentsForm, that.addressesForm, that.incomeForm, that.relatedPeopleForm]
        .forEach(form => {
          if (form) form.$setPristine();
        })
    }

    $scope.confirmChanges = function () {
      let command = profileUpdateService.update($scope.profile, $scope.clonedCustomerProfileModel);
      if (command) {
        command.success(() => {
          customerCache.refetch();
          $route.reload();
        });
      } else {
        $scope.disableEditMode();
      }
    };

    $scope.printCustomerProfile = () => {
      let singlePage = that.singlePage;
      that.singlePage = true;
      $timeout(() => {
        window.print();
        that.singlePage = singlePage;
      });
    };


    let constructor = () => {
      $scope.disableEditMode();
    };

    constructor();

    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
    }

  }
});
