import module from 'module';
import _ from 'lodash';

const templateUrl = require('./miscellaneous-transactions.template.html');
module.component('miscellaneousTransactions', {
  templateUrl: templateUrl,
  controller: function ($location, $filter, http, confirmation, authentication, workingDaysCache, notification,
                        loader, ledgerActionTemplateCache, commandMetadataCache, command, actionCommand,
                        commandAccessChecker) {

    const miscTransactionRouter = {
      BRANCH_CASH_TRANSFER: 'branch-cash-transfer',
      CASHIER_CASH_OVERAGE: 'cashier-cash-overage',
      CASHIER_CASH_SHORTAGE: 'cashier-cash-shortage',
      CASHIER_CHECK_CREATION: 'cashiers-check-creation',
      CASHIER_CHECK_ENCASHMENT: 'cashier-check-encashment',
      CASH_DEPOSIT_TO_BANK: 'cash-to-depository-bank',
      CASH_IN: 'funds-movement/cash-in',
      ADJUSTMENT_IN: 'funds-movement/adjustment-in',
      FOREX_CASH_IN: 'funds-movement/forex-cash-in',
      CASH_OUT: 'funds-movement/cash-out',
      ADJUSTMENT_OUT: 'funds-movement/adjustment-out',
      FOREX_CASH_OUT: 'funds-movement/forex-cash-out',
      CASH_TRANSFER_TO_CASHIER: 'cash-transfer-to-cashier',
      CASH_TRANSFER_TO_TELLER: 'cash-transfer-to-teller',
      CASH_WITHDRAWAL_FROM_BANK: 'cash-withdrawal-from-depository-bank',
      CLOSE_COUNTER: 'close-counters',
      DISTRIBUTED_MISC_CASH_DISBURSEMENT: 'inter-branch-cash-disbursement',
      END_DAY: 'end-the-day',
      MISC_CASH_DISBURSEMENT: 'misc-cash-disbursement',
      OFFICIAL_RECEIPT_IN_CASH: 'official-receipt/official-receipt-in-cash',
      OFFICIAL_RECEIPT_IN_CHECK: 'official-receipt/official-receipt-in-check',
      POS_CASH_WITHDRAWAL: 'pos-cash-withdrawal',
      START_DAY: 'start-the-day',
      TELLER_CASH_OVERAGE: 'teller-cash-overage',
      TELLER_CASH_SHORTAGE: 'teller-cash-shortage',
      FOREX_INTERBRANCH_CASH_TRANSFER: 'forex-interbranch-cash-transfer',
    };

    this.workingDay = null;
    this.filteredTransactions = [];
    this.transactions = [];

    this.transactionActive = (t) => {
      return t && t.enabled && t.workingDayStatus && this.workingDay && this.workingDay.status === t.workingDayStatus;
    };

    const subscription = ledgerActionTemplateCache.toObservable()
      .combineLatest(workingDaysCache.toObservable(), commandMetadataCache.toObservable(), commandAccessChecker.canExecuteCommandObservable(),
            (templates, workingDays, commands, canExecuteCommand) => {
        // Find working day for current branch
        this.workingDay = _.find(workingDays, {branchId: authentication.context.branchId});
        // Check & mark if transaction is available
        // Transaction is available if: working day status matches & transaction is enabled
        _.forEach(templates, (t) => {
          const commandName = actionCommand.actionTypeToCommand(t.type);
          Object.assign(t, {active: this.transactionActive(t)});
          Object.assign(t, {canExecute: canExecuteCommand(commandName)});
          // Assign redirect URL from router
          Object.assign(t, {redirectUrl: miscTransactionRouter[t.type]});

          Object.assign(t, {commandName: commandName});
        });

        return templates.filter((template) => template.enabled);
      })
      .subscribe((templates) => {
        this.transactions = templates;
        // Filtered transactions should contain only transactions allowed for user roles
        // If [transaction.rolesAllowed] contains one of user roles -> it should be considered allowed
        // Transactions with empty [transaction.rolesAllowed] are allowed by default
        this.filteredTransactions = _.filter(templates, (t) => t.canExecute);
      });

      this.redirectToTransaction = async (t) => {
        const redirect = () => $location.path(`/dashboard/miscellaneous-transactions/${t.redirectUrl}`);

        if (t.commandName) {
          const canExecuteCommand = await commandAccessChecker.canExecuteCommandPromise();
          if (canExecuteCommand(t.commandName)) {
            redirect();
          }
        } else if (t.active && t.canExecute) {
          redirect();
        }
      };

    // Cancel subscriptions
    this.$onDestroy = () => subscription.unsubscribe();
  }
});
