import module from 'module';
import templateUrl from './pawn-update-items.template.html';
import _ from "lodash";

module.component('customerPawnsUpdateItems', {
  templateUrl: templateUrl,
  controller: function ($route, $location, customerCache, pawnItemCache, confirmation, command) {
    const that = this;

    that.$onInit = async () => {
      that.customerId = Number($route.current.params['customerId']);
      that.pawnId = Number($route.current.params['pawnId']);

      await that.fetchPawn();
    };

    that.fetchPawn = async () => {
      const pawns = await customerCache.pawns(that.customerId).toPromise();
      const pawn = _.find(pawns, {id: that.pawnId});
      that.items = pawn.items;
      that.pawnTypeId = pawn.typeId
    }

    that.editItem = (item, $event) => {
      $event.stopPropagation();
      $location.path(`/customer/${that.customerId}/pawns/${that.pawnId}/update-items/${item.id}`);
    };

    that.removeItem = async (item, $event) => {
      $event.stopPropagation();

      const confirmed = await confirmation(`Do you want to delete item no. ${item.itemNumber}?`);
      if (!confirmed) {
        return;
      }

      const response = await command.execute('RemovePawnItem', {itemId: item.id}).toPromise();
      if (response.approvalRequired) {
        return;
      }

      customerCache.pawns(that.customerId).evict();
      await that.fetchPawn();
    };
  }
});
