import _ from 'lodash';
import module from 'module';

class DenominationSerialNumbersService {
  constructor(popup, systemPropertyCache) {
    this.supportedCurrencies = ['USD'];
    this.popup = popup;

    systemPropertyCache.toPromise()
      .then(systemProperties => {
        const property = _.find(systemProperties, {code: 'FOREIGN_DENOMINATION_SERIAL_NUMBERS_ENABLED'});
        this.canSave = property && property.value === 'TRUE';
    });
  }

  appendSerialNumbers(currency, transaction) {
    if (!this.canSave || !this.supportedCurrencies.includes(currency.isoCode) || !transaction || !transaction.denominationBundle) {
      return;
    }

    if (transaction.denominationBundle.incoming && transaction.denominationBundle.incoming.units) {
      const list = currency.units
        .filter(unit => unit.incomingSerialNumbers && unit.incomingSerialNumbers.length > 0)
        .map(unit => ({id: unit.id, unitCashValue: unit.unitCashValue, serialNumbers: unit.incomingSerialNumbers}));
      this.append(list, transaction.denominationBundle.incoming.units, currency.isoCode);
    }

    if (transaction.denominationBundle.outgoing && transaction.denominationBundle.outgoing.units) {
      const list = currency.units
        .filter(unit => unit.outgoingSerialNumbers && unit.outgoingSerialNumbers.length > 0)
        .map(unit => ({id: unit.id, unitCashValue: unit.unitCashValue, serialNumbers: unit.outgoingSerialNumbers}));
      this.append(list, transaction.denominationBundle.outgoing.units, currency.isoCode);
    }
  }

  append(currencyNoteList, denominationBundleUnits, currencyIsoCode) {
    try {
      if (!currencyNoteList || !currencyNoteList.length) {
        throw new Error("Empty serial numbers.");
      }

      const duplicateSerialNumbersSet = new Set();
      const serialNumbersSet = new Set();

      currencyNoteList.forEach(note => {
        const unit = _.find(denominationBundleUnits, {currencyUnitId: note.id});

        // If the unit cannot be found, that means that the serial number has been entered into the wrong bank note.
        if (!unit) {
          throw new Error(`Invalid denomination and serial number combination detected. Please remove the following serial numbers: ${note.serialNumbers}.`);
        }

        // Check for equality in the count entered and the number of serial numbers entered.
        if (note.serialNumbers.length != unit.count) {
          const diff = Math.abs(unit.count - note.serialNumbers.length);
          const numbers = diff > 1 ? 'numbers' : 'number';
          const missingOrExtra = note.serialNumbers.length < unit.count ? 'Missing' : 'Extra'
          throw new Error(`${missingOrExtra} ${diff} serial ${numbers} for banknote ${note.unitCashValue} ${currencyIsoCode}.`)
        }

        // Check for possible duplicate serial numbers from all denominations.
        note.serialNumbers.forEach(sn => {
          if (serialNumbersSet.has(sn)) {
            duplicateSerialNumbersSet.add(sn);
          }

          serialNumbersSet.add(sn);
        });

        unit.serialNumbers = note.serialNumbers;
      });

      if (duplicateSerialNumbersSet.size > 0) {
        const duplicates = [];
        duplicateSerialNumbersSet.forEach(value => duplicates.push(value));
        throw new Error(`Duplicate serial numbers found: ${duplicates}`);
      }
    } catch (error) {
      this.popup({
        header: 'Error',
        text: error.message,
        renderHtml: true
      });

      throw error;
    }
  };

  resetSerialNumbers(foreignCurrency) {
    if (this.canSave) {
      foreignCurrency.units
        .filter(unit => unit.type === 'NOTE')
        .forEach(note => {
          note.incomingSerialNumbers = [];
          note.outgoingSerialNumbers = [];
        });
    }
  }
}

module.factory('denominationSerialNumbersService', DenominationSerialNumbersService);