import BigNumber from 'bignumber.js';
import module from 'module';

import templateUrl from './customer-forex-operation.template.html';
import './customer-forex-operation.less';
import _ from "lodash";

class ForexOperation {
  constructor($route, authentication, command, confirmationTemplate, branchCurrencyCache, http, dict, popup,
              exchangeRateService, forexDocumentTypeCache, forexDocumentRateCache, systemPropertyCache, $scope,
              denominationSerialNumbersService, propertyConfigService, actionCategoryCache) {
    this.authentication = authentication;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.branchCurrencyCache = branchCurrencyCache;
    this.$route = $route;
    this.http = http;
    this.dict = dict;
    this.popup = popup;
    this.exchangeRateService = exchangeRateService;
    this.forexDocumentTypeCache = forexDocumentTypeCache;
    this.forexDocumentRateCache = forexDocumentRateCache;
    this.denominationSerialNumbersService = denominationSerialNumbersService;
    this.systemPropertyCache = systemPropertyCache;
    this.cfg = propertyConfigService;
    this.actionCategoryCache = actionCategoryCache;

    this.cashIn = {
      model: {
        denomination: {
          denominationBundle: {},
          currencyId: null
        }
      }
    };

    this.cashOut = {
      model: {
        denomination: {
          denominationBundle: {},
          currencyId: null
        }
      }
    };

    $scope.$watchCollection(
      '$ctrl.cashIn.model.denomination.denominationBundle.incoming.units',
      () => this.requireRecalculate = true // upon change in denominations forces to recalculate exchange amount
    );

    this.commandInput = {
      category: null,
      categoryId: null,
      remittancePartnerName: null,
      documentTypeId: null,
      documentRateId: null
    };

    this.categories = [
      {
        value: 'PURCHASE',
        label: 'Forex exchange (Buying)',
        actionType: "FOREX_PURCHASE"
      }, {
        value: 'SELL',
        label: 'Forex exchange (Selling)',
        actionType: "FOREX_SALE"
      }, {
        value: 'REMITTANCE_EXCHANGE',
        label: 'Remittance exchange',
        actionType: "REMITTANCE_EXCHANGE"
      }
    ];

    this.remittancePartners = [
      'BDO MTS',
      'BDO Remittance',
      'MoneyGram',
      'Western Union'
    ];

    this.currencyWarnings = [];
    this.dict.onLoadingComplete(() => this.sourcesOfFunds = this.dict['FOREX_SOURCE_OF_FUNDS']);
    this.isDenominated = false;
  }

  async $onInit() {
    [this.transactingStates,
      this.currencies,
      this.documentTypes,
      this.documentRates,
      this.systemProperties,
      this.actionCategories] = await Promise.all([
        this.http.get(`/forex/transacting-state?branchId=${this.authentication.context.branchId}`).toPromise(),
        this.branchCurrencyCache.withParam(Number(this.authentication.context.branchId)).toPromise(),
        this.forexDocumentTypeCache.toPromise(),
        this.forexDocumentRateCache.toPromise(),
        this.systemPropertyCache.toPromise(),
        this.actionCategoryCache.toPromise()
    ]);

    const mainCurrencyIdx = this.currencies.findIndex(it => it.isoCode === this.mainCurrencyIso);
    this.mainCurrency = this.currencies[mainCurrencyIdx];
    if (mainCurrencyIdx > -1) {
      this.currencies.splice(mainCurrencyIdx, 1);
    }

    this.commandInput = {
      ...this.commandInput,
      customerId: this.customerId,
      branchId: this.authentication.context.branchId,
      userId: this.authentication.context.id
    }

    this.transactingEnabledByCurrencyId = this.transactingStates
      .reduce((acc, val) => {
        acc[val.currencyId] = val.transactingEnabled;
        return acc;
      }, {});
      
    this.canSaveNoteSerialNumber = _.find(this.systemProperties, {code: 'FOREIGN_DENOMINATION_SERIAL_NUMBERS_ENABLED'}).value === 'TRUE';
  }

  isRemittanceExchangeSelected() {
    return this.commandInput.category === 'REMITTANCE_EXCHANGE';
  }

  hasFormula() {
    return !_.isEmpty(this.formulas);
  }

  setExchangeRate() {
    const {currencyId, category} = this.commandInput;

    if (currencyId) {
      this.currencyExchangeRate = this.exchangeRateService.currentBranchExchangeRates(currencyId);
      this.foreignCurrency = this.currencies.find(it => it.id === currencyId);
      this.noteValues = this.foreignCurrency.units.map(u => u.unitCashValue).sort().join(', ');
      this.commandInput.exchangeRateId = this.currencyExchangeRate.id;
      this.formulas = this.exchangeRateService.getFormulas(category, currencyId);
      this.buildModel();
      this.recalculateExchangeAmount();
    } else {
      this.currencyExchangeRate = {
        buyRate: undefined,
        sellRate: undefined
      }
    }
  }

  updateCashInOutObjects() {
    if (this.commandInput.category === 'SELL') {
      this.cashOut.totalAmountModel = this.commandInput.amountInCurrency;
      this.cashIn.totalAmountModel = this.commandInput.amount;
    } else {
      this.cashIn.totalAmountModel = this.commandInput.amountInCurrency;
      this.cashOut.totalAmountModel = this.commandInput.amount;
    }
  }

  setInitialExchangeAmount() {
    let amount = new BigNumber(this.exchangeRate)
      .plus(this.idAdditionalRate)
      .mul(this.commandInput.amountInCurrency);

    if (this.isRemittanceExchangeSelected()) {
      amount = amount.round(2, BigNumber.ROUND_DOWN);
    }

    this.commandInput.amount = amount.toNumber();
  }

  async recalculateExchangeAmount() {
    const {currencyId, category} = this.commandInput;
    const denomination = category === 'SELL' ? this.cashOut.model.denomination : this.cashIn.model.denomination;
    this.exchangeRate = this.exchangeRateService.getExchangeRate(currencyId, denomination, category);

    this.setInitialExchangeAmount();
    this.updateCashInOutObjects();
  }

  updateInputDenominationBundles() {
    if (['PURCHASE', 'REMITTANCE_EXCHANGE'].includes(this.commandInput.category)) {
      this.commandInput.denominationBundle = this.cashOut.model.denomination.denominationBundle;
      this.commandInput.foreignCurrencyDenominationBundle = this.cashIn.model.denomination.denominationBundle;
    } else {
      this.commandInput.denominationBundle = this.cashIn.model.denomination.denominationBundle;
      this.commandInput.foreignCurrencyDenominationBundle = this.cashOut.model.denomination.denominationBundle;
    }
  }

  async remoteRecalculateExchangeAmount() {
    this.updateInputDenominationBundles();

    const {group} = this.commandInput.foreignCurrencyDenominationBundle;
    if (!group) {
      // denomination not filled
      return;
    }

    const result = await this.http.post('/forex/exchange/calculate', {
      transactionCategory: this.commandInput.category,
      documentRateId: this.commandInput.documentRateId,
      foreignCurrencyDenominationBundle: this.commandInput.foreignCurrencyDenominationBundle
    }).toPromise();

    this.commandInput.amount = result.exchangeAmount;
    this.updateCashInOutObjects();
    this.requireRecalculate = false;
  }

  onAmountInCurrencyChange() {
    this.setDocumentRate();
    this.setExchangeRate();
    this.setCategoryId();
    this.isDenominated = false;

    if (!this.isRemittanceExchangeSelected()) {
      this.commandInput.remittancePartnerName = null;
    }
  }

  allowedCurrencies() {
    if (this.isRemittanceExchangeSelected()) {
      return this.currencies.filter(c => c.isoCode === 'USD');
    }
    return this.currencies;
  }

  transactingEnabled() {
    return !this.commandInput.currencyId
      || this.transactingEnabledByCurrencyId[this.commandInput.currencyId];
  }

  onForeignDenominationChange() {
    this.recalculateExchangeAmount();
  }

  denominate() {
    if (this.foreignCurrency) {
      this.denominationSerialNumbersService.resetSerialNumbers(this.foreignCurrency);
    }

    this.isDenominated = true;
  }

  exchange() {
    let question = 'Do you want to exchange currencies: ';

    if (this.canSaveNoteSerialNumber) {
      if (['PURCHASE', 'REMITTANCE_EXCHANGE'].includes(this.commandInput.category)) {
        this.denominationSerialNumbersService.appendSerialNumbers(this.foreignCurrency, this.cashIn.model.denomination);
      } else {
        this.denominationSerialNumbersService.appendSerialNumbers(this.foreignCurrency, this.cashOut.model.denomination);
      }
    }

    if (['PURCHASE', 'REMITTANCE_EXCHANGE'].includes(this.commandInput.category)) {
      this.commandInput.denominationBundle = this.cashOut.model.denomination.denominationBundle;
      this.commandInput.foreignCurrencyDenominationBundle = this.cashIn.model.denomination.denominationBundle;
      question += `${this.commandInput.amount} ${this.cashOut.isoCode} for ${this.commandInput.amountInCurrency} ${this.cashIn.isoCode}`;
    } else {
      this.commandInput.denominationBundle = this.cashIn.model.denomination.denominationBundle;
      this.commandInput.foreignCurrencyDenominationBundle = this.cashOut.model.denomination.denominationBundle;
      question += `${this.commandInput.amountInCurrency} ${this.cashOut.isoCode} for ${this.commandInput.amount} ${this.cashIn.isoCode}`;
    }

    this.confirmationTemplate({
      question: question,
      yesCallback: () => this.command.execute(this.commandName, {
        actionType: this.actionType,
        action: this.commandInput
      }).success(() => {
        this.$route.reload();
      })
    });
  }

  otherSourceOfFundsChosen() {
    return this.commandInput.sourceOfFunds ? this.sourcesOfFunds.find(source => source.id === this.commandInput.sourceOfFunds).code === 'OTHERS' : false;
  }

  cancel() {
    this.$route.reload();
  }

  buildModel() {
    const {currencyId, category} = this.commandInput;
    if (category === 'SELL') { //We sell foreign currency to customer
      this.commandName = 'SellForeignCurrency';
      this.actionType = 'FOREX_SALE';
      this.cashOut.group = 'OUTGOING';
      this.cashOut.isoCode = this.foreignCurrency.isoCode;
      this.cashIn.group = 'INCOMING';
      this.cashIn.isoCode = this.mainCurrencyIso;
      this.cashIn.model.denomination.currencyId = this.mainCurrency.id;
      this.cashOut.model.denomination.currencyId = currencyId;
      this.cashIn.currency = 'MAIN';
      this.cashOut.currency = 'FOREIGN';
    } else {
      if (category === 'PURCHASE') {
        this.commandName = 'PurchaseForeignCurrency';
        this.actionType = 'FOREX_PURCHASE';
      } else {
        this.commandName = 'RemittanceExchange';
        this.actionType = 'REMITTANCE_EXCHANGE';
      }
      this.cashIn.group = 'INCOMING';
      this.cashIn.isoCode = this.foreignCurrency.isoCode;
      this.cashOut.group = 'OUTGOING';
      this.cashOut.isoCode = this.mainCurrencyIso;
      this.cashIn.model.denomination.currencyId = currencyId;
      this.cashOut.model.denomination.currencyId = this.mainCurrency.id;
      this.cashIn.currency = 'FOREIGN';
      this.cashOut.currency = 'MAIN';
    }
  }

  setDocumentRate() {
    const {documentTypeId, currencyId, category} = this.commandInput;
    this.resetDocumentRate();
    if (this.documentRates && currencyId && documentTypeId) {
      const documentRate = this.documentRates
        .find(rate => rate.documentTypeId === documentTypeId && rate.currencyId === currencyId);

      if (documentRate) {
        this.commandInput.documentRateId = documentRate.id;
        if (category === 'SELL') {
          this.idRate = documentRate.sellRate;
          this.idAdditionalRate = documentRate.sellRate * -1;
        } else {
          this.idRate = documentRate.buyRate;
          this.idAdditionalRate = documentRate.buyRate;
        }
      } else {
        this.idRate = 0;
      }
    }
  }

  setCategoryId() {
    let actionCategory = _.find(this.categories, {value: this.commandInput.category});
    this.commandInput.categoryId = _.find(this.actionCategories, {actionType: actionCategory.actionType}).id;
  }

  showIdRateNote(requiredCategory) {
    const {documentTypeId, category} = this.commandInput;
    return documentTypeId && category === requiredCategory;
  }

  denominationIsValid(model) {
    return model.denomination.denominationBundle != null && model.denomination.denominationValid;
  }

  resetDocumentRate() {
    this.commandInput.documentRateId = null;
    this.idRate = null;
    this.idAdditionalRate = 0;
  }

  isExchangeDisabled() {
    return this.form.$invalid
      || !this.denominationIsValid(this.cashIn.model)
      || !this.denominationIsValid(this.cashOut.model);
  }
}

module.component('customerForexOperation', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '<',
    mainCurrencyIso: '<'
  },
  controller: ForexOperation
});
