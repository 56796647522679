import module from "module";

const templateUrl = require('./customer-pawn-items.template.html');
module.component('customerPawnItems', {
  templateUrl: templateUrl,
  bindings: {
    items: '=',
    editItem: '=',
    removeItem: '='
  },
  controller: function (pawnItemTypeCache) {
    let that = this;
    that.selectedItemId = null;

    that.itemClicked = (item, $event) => {
      $event.stopPropagation();
      that.selectedItemId = item.id;
    };

    that.hideInlinePanel = () => {
      that.selectedItemId = null;
    };

    const subscription = pawnItemTypeCache.toObservable().subscribe(types => {
      that.typeMap = {};
      for (let t of types) {
        that.typeMap[t.id] = t.name;
      }
    });

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }
});
