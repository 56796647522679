import module from 'module';
import _ from 'lodash';

const templateUrl = require('./cash-transfer-to-teller.template.html');
module.component('cashTransferToTeller', {
  templateUrl: templateUrl,
  controller: function (authentication, actionCommand, userCache, ledgerActionTemplateCache,
                        commandRoleMapCache, miscTransactionsService, commandAccessChecker) {
    const that = this;
    that.transaction = {};
    that.approvalUsers = [];

    const userSub = userCache.toObservable()
      .combineLatest(ledgerActionTemplateCache.toObservable(), commandAccessChecker.canApproveCommandObservable(),
        (users, templates, canApproveCommand) => {
        const template = _.find(templates, {type: 'CASH_TRANSFER_TO_TELLER'});
        template.commandName = actionCommand.actionTypeToCommand(template.type);
        const branchId = authentication.context.branchId;
        that.approvalUsers = miscTransactionsService.filterApprovalUsers(branchId, template, users, canApproveCommand);
        return users;
      })
      .subscribe(users => {
      });

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      actionCommand.executeAndShowValidation('CASH_TRANSFER_TO_TELLER', that.transaction);
    };

    that.$onDestroy = () => {
      userSub.unsubscribe();
    }
  }
});
