import module from "module";

module.factory('actionCommand', ($location, command, userCounterService, authentication, confirmation, modalPrintPreviewService) => {
  let service = {};

  const actionTypeToCommandMap = {
    BRANCH_CASH_TRANSFER: 'BranchCashTransfer',
    CASHIER_CASH_OVERAGE: 'CashierCashOverage',
    CASHIER_CASH_SHORTAGE: 'CashierCashShortage',
    CASHIER_CHECK_CREATION: 'CashierCheckCreation',
    CASHIER_CHECK_ENCASHMENT: 'CashierCheckEncashment',
    CASH_DEPOSIT_TO_BANK: 'CashDepositToBank',
    CASH_IN: 'CashIn',
    CASH_OUT: 'CashOut',
    ADJUSTMENT_IN: 'AdjustmentIn',
    ADJUSTMENT_OUT: 'AdjustmentOut',
    FOREX_CASH_IN: 'ForexCashIn',
    FOREX_CASH_OUT: 'ForexCashOut',
    CASH_TRANSFER_TO_CASHIER: 'CashTransferToCashier',
    CASH_TRANSFER_TO_TELLER: 'CashTransferToTeller',
    CASH_WITHDRAWAL_FROM_BANK: 'CashWithdrawalFromBank',
    CLOSE_COUNTER: 'CloseCounter',
    DISTRIBUTED_MISC_CASH_DISBURSEMENT: 'DistributedMiscCashDisbursement',
    END_DAY: 'EndDay',
    MISC_CASH_DISBURSEMENT: 'MiscCashDisbursement',
    OFFICIAL_RECEIPT_IN_CASH: 'OfficialReceiptInCash',
    OFFICIAL_RECEIPT_IN_CHECK: 'OfficialReceiptInCheck',
    POS_CASH_WITHDRAWAL: 'PosCashWithdrawal',
    START_DAY: 'StartDay',
    TELLER_CASH_OVERAGE: 'TellerCashOverage',
    TELLER_CASH_SHORTAGE: 'TellerCashShortage',
    FOREX_INTERBRANCH_CASH_TRANSFER: 'ForexInterbranchCashTransfer'
  };

  service.actionTypeToCommand = (actionType) => actionTypeToCommandMap[actionType];

  const defaultOnSuccess = () => {
    userCounterService.refresh();
    $location.path("/dashboard/miscellaneous-transactions");
  };

  /**
   * Executes the action as command, based on internal mapping of actionType to command
   *
   * By default all callbacks attached by `command` service are attached with exception:
   * - if onSuccess is not provided -> default command callback + redirect back to transaction list
   *                                                            + refetch userCounterCache
   *
   * When onSuccess is provided the extra callback (all but default command callback) will be overridden.
   */
  service.execute = (actionType, request, onSuccess) => {
    // Set [userId] of transaction to id of authenticated user
    request.userId = authentication.context.id;
    const commandName = service.actionTypeToCommand(actionType);
    const actionRequest = {
      actionType: actionType,
      action: request,
      approvalUserId: request.approvalUserId
    };

    let successCb = onSuccess ? onSuccess : defaultOnSuccess;
    return command.execute(commandName, actionRequest)
      .success(res => successCb(res));
  };

  service.executeAndShowValidation = async (actionType, request, onSuccess) => {
    const response = await service.execute(actionType, request, onSuccess).toPromise();
    modalPrintPreviewService.show('MISCELLANEOUS_VALIDATION_SLIP', {actionDataId: response.output});
  };

  service.cancelChanges = () => {
    confirmation('Do you want to cancel? Canceling will discard all changes.',
      () => $location.path(`/dashboard/miscellaneous-transactions`));
  };

  return service;
});
