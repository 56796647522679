import module from 'module';
import _ from 'lodash';

const templateUrl = require('./cash-transfer-to-cashier.template.html');
module.component('cashTransferToCashier', {
  templateUrl: templateUrl,
  controller: function (authentication, actionCommand, userCache, userCounterService,
                        ledgerActionTemplateCache, miscTransactionsService, commandRoleMapCache, commandAccessChecker) {
    const that = this;

    that.transaction = {};
    that.approvalUsers = [];
    that.assetCounter = {};

    const userSub = userCache.toObservable()
      .combineLatest(ledgerActionTemplateCache.toObservable(), commandAccessChecker.canApproveCommandObservable(),
        (users, templates, canApproveCommand) => {
        const template = _.find(templates, {type: 'CASH_TRANSFER_TO_CASHIER'});
        const branchId = authentication.context.branchId;
        that.approvalUsers = miscTransactionsService.filterApprovalUsers(branchId, template, users, canApproveCommand);
        return users;
      })
      .subscribe(users => {
        that.user = _.find(users, {id: authentication.context.id});
      });

    // Read asset counter of currently logged user
    userCounterService.toPromise()
      .then(counter => that.assetCounter = counter);

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => actionCommand.execute('CASH_TRANSFER_TO_CASHIER', that.transaction);

    that.$onDestroy = () => {
      userSub.unsubscribe();
    }
  }
});
