import module from 'module';
import _ from 'lodash';
import templateUrl from './pawn-update-item.template.html';

module.component('customerPawnsUpdateItem', {
  templateUrl: templateUrl,
  controller: function ($route, $location, command, customerCache, http,
                        pawnItemCache, confirmation, notification, popup) {

    this.customerId = Number($route.current.params['customerId']);
    this.pawnId = Number($route.current.params['pawnId']);
    this.pawnItemId = Number($route.current.params['itemId']);

    this.$onInit = async () => {
      const pawns = await customerCache.pawns(this.customerId).toPromise();
      this.pawn = _.find(pawns, {id: this.pawnId});
      this.pawnTypeId = this.pawn.typeId;

      const item = await pawnItemCache.withParam(this.pawnItemId).toPromise()
      this.pawn.items = _.map(this.pawn.items, (item) => {
        if (item.id === this.pawnItemId) {
          return item;
        }
        return item;
      });
      this.pawnItem = item;
    };

    this.saveItem = async (item) => {
      const request = {
        ...this.pawnItem,
        type: 'PAWNED',
        itemId: this.pawnItem.id,
        migrated: item.migrated,
        categoryId: item.category.id,
        typeId: item.type.id,
        fileIds: item.files ? item.files.map(f => f.id) : [],
        subtypeId: item.subtype ? item.subtype.id : null,
        customType: item.customType,
        quantity: item.quantity,
        defectIds: item.defectIds,
        valuation: item.valuation,
        overloadedValuation: item.overloadedValuation,
        deprecatedProductNumber: item.deprecatedProductNumber,
        remarks: item.remarks,
        attributes: item.attributes,
        plain: !item.withStones,
        weight: item.weight,
        stones: item.stones ? _.union(item.stones.valuable, item.stones.nonValuable) : null,
        origin: item.origin,
        metal: item.metal ? item.metal : null,
        autoDescription: item.autoDescription
      };

      await getOrCreateOverriddenMetalRate(request.metal);

      if (request.stones && request.stones.length > 0) {
        _.forEach(request.stones, (stone) => {
          Object.assign(stone, {stoneTypeId: stone.type.id});
          Object.assign(stone, {stoneColourId: stone.stoneColour ? stone.stoneColour.id : null});
          Object.assign(stone, {stoneRateId: stone.stoneRate ? stone.stoneRate.id : null});
        })
      }

      confirmation(`Do you want to update appraisal ${item.itemNumber}?`, () => {
        command.execute('EditPawnItem', request, {nxLoaderText: 'Updating pawn item'})
          .success(() => {
            notification.show("Update performed successfully");
            customerCache.pawns(this.customerId).refetch();
            pawnItemCache.withParam(item.id).refetch();

            popup({
              header: "Pawn Item Update Successful",
              text: "Pawn item update successful. Please print the updated pawn ticket."
            });

            this.redirectBack();
          })
          .error(() => {
            notification.show("Failed to update appraisal");
            customerCache.pawns(this.customerId).refetch();
          });
      });
    };

    const getOrCreateOverriddenMetalRate = async (metal) => {
      // Create or fetch an existing pawn metal rate with the same fineness and price per gram.
      if (metal && metal.rate && metal.rate.overridden) {
        const metalRate = metal.rate;
        const pawnMetalRateRequest = {
          systemDate: metalRate.systemDate,
          name: metalRate.name,
          metalTypeIds: metalRate.metalTypeIds,
          fineness: metalRate.fineness,
          pricePerGram: metalRate.pricePerGram,
          orderNo: 0,
          overridden: true,
          type: 'PAWNED'
        };

        const overriddenMetalRate = await http.post(`/products/pawns/get-or-create-overridden-metal-rate`, pawnMetalRateRequest, {nxLoaderText: 'Updating metal prices'}).toPromise();
        metal.metalRateId = overriddenMetalRate.id;
      }
    }

    this.redirectBack = () => {
      $location.path(`/customer/${this.customerId}/pawns/${this.pawnId}`);
    };
  }
});
